/** @jsx createElementEntity */
import { createElementEntity } from "../utils/jsx-entity";
import { getAbsoluteHref } from "../utils/media-url-utils";
import { COLLISION_LAYERS } from "../utils/constants";
import { FLOATY_OBJECT_FLAGS } from "../systems/floaty-object-system";

// TODO: Implement a selector for different 3D models
import girl1 from "../assets/models/girl1_nh.glb";
import girl2 from "../assets/models/girl2_nh.glb";
import girl3 from "../assets/models/girl3_nh.glb";
import girl4 from "../assets/models/girl4_nh.glb";
import girl5 from "../assets/models/girl5_nh.glb";
import boy1 from "../assets/models/boy1_nh.glb";
import boy2 from "../assets/models/boy2_nh.glb";
import boy3 from "../assets/models/boy3_nh.glb";
import eric from "../assets/models/eric.glb";
import duck from "../assets/models/duck_v11.glb";

const modelMap = {
    eric,
    duck,
    girl1,
    girl2,
    girl3,
    girl4,
    girl5,
    boy1,
    boy2,
    boy3
};

export function NPCPrefab(props) {
    const selectedModel = modelMap[props.model] || girl1; // Default to 'girl1' if no model is selected

    return (
        <entity
            name="NPC"
            networked
            locked
            billboard
            studentsCanMove
            nickname
            networkedTransform
            morphAudioFeedback
            mediaLoader={{
                src: getAbsoluteHref(location.href, selectedModel),
                resize: true,
                recenter: true,
                animateLoad: true,
                isObjectMenuTarget: true
            }}
            agentAudio={{ prompt: props.prompt, voice: props.voice, object_id: props.object_id }}
            cursorRaycastable
            remoteHoverTarget
            handCollisionTarget
            offersRemoteConstraint
            offersHandConstraint
            destroyAtExtremeDistance
            holdable
            scale={[1, 1, 1]}
            rigidbody={{ collisionGroup: COLLISION_LAYERS.INTERACTABLES, collisionMask: COLLISION_LAYERS.HANDS }}
            floatyObject={{
                flags: FLOATY_OBJECT_FLAGS.UNTHROWABLE,
                releaseGravity: 0
            }}
            grabbable={{ cursor: true, hand: true }}
            deletable
        ></entity>
    );
}
