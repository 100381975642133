import React, { useEffect } from "react";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

export function AvatarPicker({ results, selected, setSelected, setSelectedHairStyle }) {
    const handleClick = avi => {
        setSelected(avi);
        if(avi.style){
            setSelectedHairStyle(avi.style);
        }
        if(avi?.location === "body") {
            window.APP.store.update({
                profile: {bodyAccessoryId: avi.id}
            });
        } else if (avi?.location === "head") {
            window.APP.store.update({
                profile: {headAccessoryId: avi.id}
            });
        }
    
        console.log(avi);
    };

    if (!selected) {
        return <p>Loading...</p>;
    }

    return (
        <Row 
            className="pe-3 ps-3"
            style={{
                display: "flex",
                flexWrap: "wrap", 
                maxHeight: "350px", 
                overflowY: "auto",
        }}>
            {results.map(avi => {
                if (avi.thumbnail.startsWith("/")) {
                    avi.thumbnail = "http://localhost:8000" + avi.thumbnail;
                }
                return (
                    <Col
                        key={avi.id}
                        xs={4}
                        sm={3}
                        md={3}
                        lg={3}
                        xxl={3}
                        className={
                            avi.id === selected.id
                                ? "border-success border bg-success p-0"
                                : "bg-white border rounded p-0"
                        }
                        onClick={() => handleClick(avi)}
                    >
                        <img draggable="false" className="img-fluid" src={avi.thumbnail} />
                    </Col>
                );
            })}
        </Row>
    );
}
