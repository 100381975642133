import qsTruthy from "./utils/qs_truthy";
import { hackyMobileSafariTest } from "./utils/detect-touchscreen";
import { createNetworkedEntity } from "./utils/create-networked-entity";

const isMobile = AFRAME.utils.device.isMobile();
const forceEnableTouchscreen = hackyMobileSafariTest();

import { ObjectContentOrigins } from "./utils/object-types";
import { SOUND_ENTER_SCENE } from "./systems/sound-effects-system";
import { MediaDevicesEvents } from "./utils/media-devices-utils";
import { addComponent, removeEntity } from "bitecs";
import { LiveFeed } from "./bit-components";
import { moveToSpawnPoint } from "./bit/systems/waypoint";

export default class SceneEntryManager {
    constructor() {
        this.store = window.APP.store;
        this.scene = document.querySelector("a-scene");
        this.rightCursorController = document.getElementById("right-cursor-controller");
        this.leftCursorController = document.getElementById("left-cursor-controller");
        this.avatarRig = document.getElementById("avatar-rig");
        this.currentVideoShareEntity = null;
        this.currentScreenShareEntity = null;
        this._entered = false;
    }

    init = () => {
        console.log("Initializing Entry Manager");
        this.whenSceneLoaded(() => {
            if (!isMobile) {
                this.scene.renderer.setPixelRatio(1);
            }
            this.rightCursorController.components["cursor-controller"].enabled = false;
            this.leftCursorController.components["cursor-controller"].enabled = false;
            this._setupBlocking();
        });
    };

    enterScene = async () => {
        // Stop Scene Camera
        document.getElementById("viewing-camera").removeAttribute("scene-preview-camera");

        // Spawn user
        moveToSpawnPoint(APP.world, this.scene.systems["hubs-systems"].characterController);

        if (isMobile || forceEnableTouchscreen || qsTruthy("force_enable_touchscreen")) {
            this.avatarRig.setAttribute("virtual-gamepad-controls", {});
        }

        this._setupPlayerRig();
        this._setupMedia();
        this._setupBlocking();

        if (qsTruthy("offline")) return;

        this._spawnAvatar();

        this.scene.systems["hubs-systems"].soundEffectsSystem.playSoundOneShot(SOUND_ENTER_SCENE);

        this.scene.classList.remove("hand-cursor");
        this.scene.classList.add("no-cursor");

        document.getElementById("Root").classList.remove("all-pointer-events");

        this.rightCursorController.components["cursor-controller"].enabled = true;
        this.leftCursorController.components["cursor-controller"].enabled = true;
        this._entered = true;

        this.scene.addState("entered");
    };

    whenSceneLoaded = callback => {
        if (this.scene.hasLoaded) {
            callback();
        } else {
            this.scene.addEventListener("loaded", callback);
        }
    };

    exitScene = () => {
        if (this.scene.renderer) {
            this.scene.renderer.setAnimationLoop(null); // Stop animation loop, TODO A-Frame should do this
        }
        // this.scene.parentNode.removeChild(this.scene);
    };

    _setupPlayerRig = () => {
        this._setPlayerInfoFromProfile();

        // Explict user action changed avatar or updated existing avatar.
        this.scene.addEventListener("avatar_updated", () => this._setPlayerInfoFromProfile(true));

        // Store updates can occur to avatar id in cases like error, auth reset, etc.
        this.store.addEventListener("statechanged", () => this._setPlayerInfoFromProfile());
    };

    _setPlayerInfoFromProfile = async (force = false) => {
        const avatarId = this.store.state.profile.avatarId;
        const hairId = this.store.state.profile.hairId;
        const bodyTextureId = this.store.state.profile.bodyTextureId;
        const headAccessoryId = this.store.state.profile.headAccessoryId;
        const bodyAccessoryId = this.store.state.profile.bodyAccessoryId;

        const readyAvatarModel = await fetch(process.env.REACT_APP_MM_API_URL + "avatars/" + avatarId)
            .then(resp => resp.json())
            .then(data => {
                if (data.glb.startsWith("/")) {
                    return "http://localhost:8000" + data.glb;
                } else {
                    return data.glb;
                }
            });

        const readyHairModel = await fetch(process.env.REACT_APP_MM_API_URL + "avatars/hair/" + hairId)
            .then(resp => resp.json())
            .then(data => {
                if (data.glb.startsWith("/")) {
                    return "http://localhost:8000" + data.glb;
                } else {
                    return data.glb;
                }
            });

        const readyHeadAccessoryModel = await fetch(
            process.env.REACT_APP_MM_API_URL + "avatars/accessory/" + headAccessoryId
        )
            .then(resp => resp.json())
            .then(data => {
                if (data.glb.startsWith("/")) {
                    return "http://localhost:8000" + data.glb;
                } else {
                    return data.glb;
                }
            });

        const readyBodyAccessoryModel = await fetch(
            process.env.REACT_APP_MM_API_URL + "avatars/accessory/" + bodyAccessoryId
        )
            .then(resp => resp.json())
            .then(data => {
                if (data.glb.startsWith("/")) {
                    return "http://localhost:8000" + data.glb;
                } else {
                    return data.glb;
                }
            });

        const readyBodyTexture = await fetch(process.env.REACT_APP_MM_API_URL + "avatars/bodytexture/" + bodyTextureId)
            .then(resp => resp.json())
            .then(data => {
                if (data.texture_file.startsWith("/")) {
                    return "http://localhost:8000" + data.texture_file;
                } else {
                    return data.texture_file;
                }
            });

        this.avatarRig.setAttribute("player-info", {
            avatarSrc: readyAvatarModel,
            bodyAccessorySrc: readyBodyAccessoryModel,
            headAccessorySrc: readyHeadAccessoryModel,
            bodyTextureSrc: readyBodyTexture,
            hairSrc: readyHairModel
        });
    };

    _setupBlocking = () => {
        document.body.addEventListener("blocked", ev => {
            NAF.connection.entities.removeEntitiesOfClient(ev.detail.clientId);
        });

        document.body.addEventListener("unblocked", ev => {
            NAF.connection.entities.completeSync(ev.detail.clientId, true);
        });
    };

    _setupMedia = () => {
        const spawnMediaInfrontOfPlayer = (src, streamType) => {
            console.log("Got spawnMedia, should be mediastream");
            if (src instanceof MediaStream) {
                if (!window.APP.objectHelper.can("can_share_video")) return;
                console.log("It is mediaStream with src", "hubs://clients/" + NAF.clientId + "/" + streamType);
                const eid = createNetworkedEntity(APP.world, "media", {
                    src: "hubs://clients/" + NAF.clientId + "/" + streamType,
                    recenter: true,
                    resize: true
                });
                addComponent(APP.world, LiveFeed, eid);
                const avatarPov = document.querySelector("#avatar-pov-node").object3D;
                const obj = APP.world.eid2obj.get(eid);
                obj.scale.set(2.7, 2.7, 2.7);
                obj.position.copy(avatarPov.localToWorld(new THREE.Vector3(0, 0, -1.7)));
                obj.lookAt(avatarPov.getWorldPosition(new THREE.Vector3()));
                return eid;
            } else {
                if (!window.APP.objectHelper.can("can_create")) return;
                const eid = createNetworkedEntity(APP.world, "media", { src: src, recenter: true, resize: true });
                const avatarPov = document.querySelector("#avatar-pov-node").object3D;
                const obj = APP.world.eid2obj.get(eid);
                obj.position.copy(avatarPov.localToWorld(new THREE.Vector3(0, 0, -1.7)));
                obj.scale.set(1.7, 1.7, 1.7);
                obj.lookAt(avatarPov.getWorldPosition(new THREE.Vector3()));

                setTimeout(() => {
                    window.APP.objectHelper.save(eid, null, src, src);
                }, 1000);
                return eid;
            }
        };

        this.scene.addEventListener("add_media", e => {
            const contentOrigin = e.detail instanceof File ? ObjectContentOrigins.FILE : ObjectContentOrigins.URL;

            spawnMediaInfrontOfPlayer(e.detail);
        });

        document.addEventListener("dragenter", e => {
            e.preventDefault();
            document.getElementById("dragoverContainer").style.display = "flex";
        });

        document.addEventListener("dragleave", e => {
            e.preventDefault();
            document.getElementById("dragoverContainer").style.display = "none";
        });

        document.addEventListener("drop", e => {
            e.preventDefault();
            document.getElementById("dragoverContainer").style.display = "none";
        });

        document.addEventListener("dragover", e => {
            e.preventDefault();
        });

        this.scene.addEventListener("action_share_camera", event => {
            this.currentVideoShareEntity = spawnMediaInfrontOfPlayer(event.detail.newStream, "camera");
            console.log("Sharing ", this.currentVideoShareEntity);

            this.scene.emit("share_video_enabled", {
                source: "camera"
            });

            this.scene.addState("sharing_video");
            APP.scene.emit("camera_entity_created");
        });

        this.scene.addEventListener("action_share_screen", () => {
            this.currentScreenShareEntity = spawnMediaInfrontOfPlayer(event.detail.newStream, "screen");
            console.log("Sharing ", this.currentScreenShareEntity);

            this.scene.emit("share_video_enabled", {
                source: "screen"
            });
            this.scene.addState("sharing_video");

            APP.scene.emit("screen_entity_created");
        });

        this.scene.addEventListener("camera_share_ended", async () => {
            console.log("CAMERA SHARE ENDED");

            if (this.currentVideoShareEntity) {
                removeEntity(APP.world, this.currentVideoShareEntity);
            }

            this.currentVideoShareEntity = null;
            APP.scene.emit("camera_entity_destroyed");
        });

        this.scene.addEventListener("screen_share_ended", async () => {
            console.log("VIDEO SHARE ENDED");

            if (this.currentScreenShareEntity) {
                removeEntity(APP.world, this.currentScreenShareEntity);
            }

            this.currentScreenShareEntity = null;
            APP.scene.emit("screen_entity_destroyed");
        });

        this.scene.addEventListener(MediaDevicesEvents.MIC_SHARE_ENDED, async () => {
            await APP.mediaDevicesManager.stopMicShare();
        });
    };

    _spawnAvatar = () => {
        this.avatarRig.setAttribute("networked", "template: #remote-avatar; attachTemplateToLocal: false;");
        this.avatarRig.emit("entered");
    };
}
