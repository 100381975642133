import React, { useState, useEffect } from "react";
import { Canvas } from "@react-three/fiber";

import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import { AvatarPicker } from "./AvatarPicker";

export function HairMenu(props) {
    const [selectedHairStyle, setSelectedHairStyle] = useState(props.hair?.style);
    const HAIR_COLORS = {
        white : "#f5f1ef",
        black : "#000000",  
        brown : "#84310a",
        orange : "#ea8815",
        blonde : "#f6b26b", 
    };
    const [hairColor, setHairColor] = useState(props.hair?.color);
    const [colorFilterArr, setColorFilterArr] = useState(props.hairs.filter(a => a.color === hairColor));

    useEffect(() => {
        if(props.hairs && hairColor){
            setColorFilterArr(props.hairs.filter(a => a.color === hairColor));
        }
    }, [props.hairs, hairColor]);

    useEffect(() => {
        if(selectedHairStyle && hairColor){
            const newSpecHair = props.hairs.find(a => ((a.color === hairColor) && (a.style === selectedHairStyle)));
            props.setHair(newSpecHair);
            window.APP.store.update({
                profile: {hairId: newSpecHair.id}
            });
        }
    }, [selectedHairStyle, hairColor]);

    return (
        <div className="mt-3">
            <p>Select Hair Color</p>
            <Row className="flex gap-2 justify-content-center">
            {Object.entries(HAIR_COLORS).map(([name, color]) => (
                <Col
                    xs={2}
                    sm={2}
                    md={2}
                    lg={2}
                    xxl={2}
                    className="p-0 m-0 d-flex align-items-center justify-content-center"
                >
                    <Button
                        key={name}
                        className={
                            name === hairColor
                                ? "border-success border p-0"
                                : "border rounded p-0"
                        }
                        style={{
                            backgroundColor: color,
                            borderColor: "#000",
                            width: "60px",
                            height: "60px",
                        }}
                        onClick={() => setHairColor(name)}
                    >
                    </Button>
                </Col>
            ))}
            </Row>

            {colorFilterArr ? (
                <div className="mt-3">
                    <p>Select Hairstyle</p>
                    <AvatarPicker 
                    results={colorFilterArr}
                    selected={props.hair}
                    setSelected={props.setHair}
                    setSelectedHairStyle={setSelectedHairStyle}
                />  
                </div>
            ) : (
                <p>No Hair matches your selection</p>
            )}
        </div>
    )
    
}