/** @jsx createElementEntity */
import { defineQuery, exitQuery, enterQuery } from "bitecs";
import { AIAgent } from "../../bit-components"; // Adjust imports as needed
import * as THREE from "three";

const avatarWorldPosition = new THREE.Vector3(); // To store avatar world position
const npcWorldPosition = new THREE.Vector3(); // Reusable vector
const proximityStates = new Map(); // To track the state of audio publishing per NPC

// Query for all AI agents (NPCs)
const aiAgentQuery = defineQuery([AIAgent]);
const exitedAgentQuery = exitQuery(aiAgentQuery) // => Every thing that loses their AIAgent Component

function kickAgent() {
    const room = window.APP.dialog.room;
    const agentParticipant = [...room.remoteParticipants.values()].find(participant =>
        participant.identity.startsWith("agent")
    );
    // TODO: Kick by object_id in metadata to allow multiple agents

    if (!agentParticipant) {
        console.warn("NPC participant not found (identity starts with 'agent').");
        return;
    }

    window.APP.objectHelper.kick_agent(agentParticipant.identity);
}

function dispatchAgent(eid, prompt, voice, object_id) {
    const room = window.APP.dialog.room;
    const agentParticipant = [...room.remoteParticipants.values()].find(participant =>
        participant.identity.startsWith("agent")
    );

    if (agentParticipant) {
        // Already have an agent, skip
        return;
    }

    console.log("Dispatching agent", eid, prompt, voice);
    window.APP.objectHelper.dispatchAgent(eid, prompt, voice);
}

function handleNpcAudioPermission(allow) {
    // Allow agent to connect first
    setTimeout(() => {
        const room = window.APP.dialog.room;
        const localParticipant = room.localParticipant;

        // Find the participant with identity starting with "agent"
        const agentParticipant = [...room.remoteParticipants.values()].find(participant =>
            participant.identity.startsWith("agent")
        );

        if (!agentParticipant) {
            console.warn("NPC participant not found (identity starts with 'agent').");
            return;
        }

        // Set permissions to allow or restrict the agent (NPC) from listening to local audio
        console.log(allow ? "Allowing NPC to subscribe" : "Denying NPC to subscribe");

        localParticipant.setTrackSubscriptionPermissions(allow, [
            {
                participantIdentity: agentParticipant.identity,
                allowAll: allow // Allow all tracks (audio/video) for the agent
            }
        ]);
    }, 2000);
}

export function npcProximityAudioSystem(world) {
    const avatar = document.getElementById("avatar-pov-node").object3D;
    avatar.getWorldPosition(avatarWorldPosition);

    // Iterate over all AI agents (NPCs) in the world
    aiAgentQuery(world).forEach(eid => {
        if(!window.APP.entryManager._entered) return;

        const npcObject = world.eid2obj.get(eid); // Get NPC object
        if (!npcObject) return;

        npcObject.getWorldPosition(npcWorldPosition);

        const distance = avatarWorldPosition.distanceTo(npcWorldPosition);
        const proximityThreshold = 3.2; // Define how far the avatar can be to still publish audio
        const isInProximity = distance < proximityThreshold;

        // Check the current state in proximityStates and toggle audio publishing accordingly
        if (isInProximity && !proximityStates.get(eid)) {
            console.log("Entered proximity!");
            proximityStates.set(eid, true);
            // handleNpcAudioSubscription(true); // Subscribe to NPC audio track
            handleNpcAudioPermission(true); // Allow NPC to listen to local audio
            // TODO: Do not dispatch agent unless you are the first person to approach it
            dispatchAgent(eid, npcObject.prompt, npcObject.voice, npcObject.object_id);
        } else if (!isInProximity && proximityStates.get(eid)) {
            console.log("Left proximity!");
            proximityStates.set(eid, false);
            // handleNpcAudioSubscription(false); // Unsubscribe from NPC audio track
            handleNpcAudioPermission(false); // Restrict NPC from listening to local audio
            // TODO: Do not kick agent unless you are the last person to walk away
            kickAgent(eid, npcObject.prompt, npcObject.voice, npcObject.object_id);
        }
    });
    
    
    // Iterate over all removed AI agents (NPCs) in the world
    exitedAgentQuery(world).forEach(eid => {
        console.log("Its true that the AIAgent component got removed");
        kickAgent();
    });
}
