import React, { useState, useEffect } from "react";
import { Canvas } from "@react-three/fiber";

import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Container } from "react-bootstrap";

import { AvatarPicker } from "./AvatarPicker";
import { AvatarPreview } from "./AvatarPreview";
import { BodyMenu } from "./BodyMenu";

import useDashApi from "../hooks/useDashApi";
import useAccessoryApi from "../hooks/useAccessoryApi";
import { hasPermissionToSpawn } from "../../utils/permissions";
import { HairMenu } from "./HairMenu";

export function ProfileStep(props) {
    const initDisplayName =
        window.APP.store.state.profile.displayName === "Name" ? "" : window.APP.store.state.profile.displayName;

    const [displayName, setDisplayName] = useState(initDisplayName);
    const initAvatarId = window.APP.store.state.profile.avatarId;
    const initHairId = window.APP.store.state.profile.hairId;
    const initBodyTextureId = window.APP.store.state.profile.bodyTextureId;
    const initHeadAccessoryId = window.APP.store.state.profile.headAccessoryId;
    const initBodyAccessoryId = window.APP.store.state.profile.bodyAccessoryId;

    const [avatars, avatarsLoading, avatarsError] = useDashApi("avatars");
    const [hairs, hairsLoading, hairsError] = useDashApi("avatars/hair");
    const [bodyTextures, bodyTexturesLoading, bodyTexturesError] = useDashApi("avatars/bodytexture");
    const [headAccessories, headAccessoriesLoading, headAccessoriesError] = useAccessoryApi(null, null, "head");
    const [bodyAccessories, bodyAccessoriesLoading, bodyAccessoriesError] = useAccessoryApi(null, null, "body");
    const [showAvatarPreview, setShowAvatarPreview] = useState(false);

    const [bodyTextureThumbnail, setBodyTextureThumbnail] = useState("");
    const [hairThumbnail, setHairThumbnail] = useState("");
    const [accessoryThumbnail, setAccessoryThumbnail] = useState("");
    const [bodyAccessoryThumbnail, setBodyAccessoryThumbnail] = useState("");

    const [avatar, setAvatar] = useState(null);
    const [hair, setHair] = useState(null);
    const [bodyTexture, setBodyTexture] = useState(null);
    const [headAccessory, setHeadAccessory] = useState(null);
    const [bodyAccessory, setBodyAccessory] = useState(null);

    const [menuState, setMenuState] = useState("main");

    const isSmallScreen = window.innerWidth < 770;

    useEffect(() => {
        if (bodyTexture) {
            if (bodyTexture.thumbnail.startsWith("/")) {
                setBodyTextureThumbnail("http://localhost:8000" + bodyTexture.thumbnail);
            } else {
                setBodyTextureThumbnail(bodyTexture.thumbnail);
            }
        }
    }, [bodyTexture]);

    useEffect(() => {
        if (hair) {
            if (hair.thumbnail.startsWith("/")) {
                setHairThumbnail("http://localhost:8000" + hair.thumbnail);
            } else {
                setHairThumbnail(hair.thumbnail);
            }
        }
    }, [hair]);

    useEffect(() => {
        if (headAccessory) {
            if (headAccessory.thumbnail.startsWith("/")) {
                setAccessoryThumbnail("http://localhost:8000" + headAccessory.thumbnail);
            } else {
                setAccessoryThumbnail(headAccessory.thumbnail);
            }
        }
    }, [headAccessory]);

    useEffect(() => {
        if (bodyAccessory) {
            if (bodyAccessory.thumbnail.startsWith("/")) {
                setBodyAccessoryThumbnail("http://localhost:8000" + bodyAccessory.thumbnail);
            } else {
                setBodyAccessoryThumbnail(bodyAccessory.thumbnail);
            }
        }
    }, [bodyAccessory]);

    useEffect(() => {
        if (avatars) {
            if (initAvatarId) {
                const initAva = avatars.find(a => a.id === initAvatarId);
                setAvatar(initAva);
            } else {
                const initAva = avatars.find(a => a.title === "Base");
                setAvatar(initAva);
            }
        }
    }, [avatars]);

    useEffect(() => {
        if (hairs) {
            if (initHairId) {
                const initHair = hairs.find(a => a.id === initHairId);
                setHair(initHair);
            } else {
                const initHair = hairs.find(a => a.title === "blackf03");
                setHair(initHair);
            }
        }
    }, [hairs]);

    useEffect(() => {
        if (headAccessories) {
            if (initHeadAccessoryId) {
                const initHeadAccessory = headAccessories.find(a => a.id === initHeadAccessoryId);
                setHeadAccessory(initHeadAccessory);
            } else {
                const initHeadAccessory = headAccessories.find(a => a.title === "No Accessory Head");
                setHeadAccessory(initHeadAccessory);
            }
        }
    }, [headAccessories]);

    useEffect(() => {
        if (bodyAccessories) {
            if (initBodyAccessoryId) {
                const initBodyAccessory = bodyAccessories.find(a => a.id === initBodyAccessoryId);
                setBodyAccessory(initBodyAccessory);
            } else {
                const initBodyAccessory = bodyAccessories.find(a => a.title === "No Accessory Body");
                setBodyAccessory(initBodyAccessory);
            }
        }
    }, [bodyAccessories]);

    useEffect(() => {
        if (bodyTextures) {
            if (initBodyTextureId) {
                const initBodyTexture = bodyTextures.find(a => a.id === initBodyTextureId);
                setBodyTexture(initBodyTexture);
            } else {
                const initBodyTexture = bodyTextures.find(a => a.title === "skin01001");
                setBodyTexture(initBodyTexture);
            }
        }
    }, [bodyTextures]);

    const handleSaveAndContinue = () => {
        if (props.fromSettingsMenu || props.fromUserList) {
            props.handleSave(avatar, displayName, hair, bodyTexture, headAccessory, bodyAccessory);
        } else {
            window.APP.store.update({
                profile: {
                    avatarId: avatar.id,
                    hairId: hair.id,
                    bodyTextureId: bodyTexture.id,
                    headAccessoryId: headAccessory.id,
                    bodyAccessoryId: bodyAccessory.id,
                    displayName: displayName
                }
            });
            window.location = props.destination;
        }
    };

    if (avatarsError) {
        console.log(avatarsError);
        return <p>Error in the avatar selector: {avatarsError.message}</p>;
    }

    if (hairsError) {
        console.log(hairsError);
        return <p>Error in the avatar selector: {hairsError.message}</p>;
    }

    if (bodyTexturesError) {
        console.log(bodyTexturesError);
        return <p>Error in the avatar selector: {bodyTexturesError.message}</p>;
    }

    if (headAccessoriesError) {
        console.log(headAccessoriesError);
        return <p>Error in the avatar selector: {headAccessoriesError.message}</p>;
    }

    if (bodyAccessoriesError) {
        console.log(bodyAccessoriesError);
        return <p>Error in the avatar selector: {bodyAccessoriesError.message}</p>;
    }

    if (
        !avatars ||
        avatarsLoading ||
        hairsLoading ||
        avatarsError ||
        hairsError ||
        !hairs ||
        !bodyTextures ||
        bodyTexturesLoading ||
        bodyTexturesError ||
        !headAccessories ||
        headAccessoriesLoading ||
        headAccessoriesError ||
        !bodyAccessories ||
        bodyAccessoriesLoading ||
        bodyAccessoriesError
    ) {
        return <p>Loading avatar selector</p>;
    }

    return (
        <div
            className={
                !props.fromSettingsMenu || !props.fromUserList
                    ? "purple-double-gradient h-100 d-flex justify-content-center align-items-center"
                    : ""
            }
        >
            <Row className="bg-white p-2 rounded border clickable" style={{ maxWidth: "760px" }}>
                <Col className="text-center" xs={12}>
                    <p className="fw-bold">Avatar Selection</p>
                </Col>
                <Col className="" xs={12} md={6}>
                    {menuState === "main" && (
                        <>
                            <Form.Group controlId="formDisplayName">
                                <Form.Label>Display Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={displayName}
                        placeholder="Enter your name here"
                                    maxLength={60}
                                    minLength={3}
                                    required
                                    onChange={e => setDisplayName(e.target.value)}
                                />
                            </Form.Group>

                            {bodyTexture && (
                                <Card
                                    className="mt-2 clickable-card"
                                    style={{
                                        height: "100px",
                                        maxHeight: "100px",
                                        overflow: "hidden"
                                    }}
                                    onClick={() => setMenuState("select-body")}
                                >
                                    <Card.Body className="m-0 p-0">
                                        <Row className="m-0 p-0 align-items-center h-100">
                                            <Col xs={8}>
                                                <Card.Text>Select Body</Card.Text>
                                            </Col>
                                            <Col
                                                xs={4}
                                                className="m-0 p-0 h-100 float-right"
                                                style={{
                                                    backgroundImage: "url(" + bodyTextureThumbnail + ")",
                                                    backgroundPosition: "center",
                                                    backgroundRepeat: "no-repeat"
                                                }}
                                            ></Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            )}

                            {hair && (
                                <Card
                                    className="mt-2 clickable-card"
                                    style={{
                                        height: "100px",
                                        maxHeight: "100px",
                                        overflow: "hidden"
                                    }}
                                    onClick={() => setMenuState("select-hair")}
                                >
                                    <Card.Body className="m-0 p-0">
                                        <Row className="m-0 p-0 align-items-center h-100">
                                            <Col xs={8}>
                                                <Card.Text>Select Hair</Card.Text>
                                            </Col>
                                            <Col
                                                xs={4}
                                                className="m-0 p-0 h-100"
                                                style={{
                                                    backgroundImage: "url(" + hairThumbnail + ")",
                                                    backgroundPosition: "center",
                                                    backgroundRepeat: "no-repeat"
                                                }}
                                            ></Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            )}

                            {headAccessory && (
                                <Card
                                    className="mt-2 clickable-card"
                                    style={{
                                        height: "100px",
                                        maxHeight: "100px",
                                        overflow: "hidden"
                                    }}
                                    onClick={() => setMenuState("select-accessory-head")}
                                >
                                    <Card.Body className="m-0 p-0">
                                        <Row className="m-0 p-0 align-items-center h-100">
                                            <Col xs={8}>
                                                <Card.Text>Select Head Accessory</Card.Text>
                                            </Col>
                                            <Col
                                                xs={4}
                                                className="m-0 p-0 h-100"
                                                style={{
                                                    backgroundImage: "url(" + accessoryThumbnail + ")",
                                                    backgroundPosition: "center",
                                                    backgroundRepeat: "no-repeat"
                                                }}
                                            ></Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            )}

                            {bodyAccessory && (
                                <Card
                                    className="mt-2"
                                    style={{
                                        height: "100px",
                                        maxHeight: "100px",
                                        overflow: "hidden"
                                    }}
                                    onClick={() => setMenuState("select-accessory-body")}
                                >
                                    <Card.Body className="m-0 p-0">
                                        <Row className="m-0 p-0 align-items-center h-100">
                                            <Col xs={8}>
                                                <Card.Text>Select Body Accessory</Card.Text>
                                            </Col>
                                            <Col
                                                xs={4}
                                                className="m-0 p-0 h-100"
                                                style={{
                                                    backgroundImage: "url(" + bodyAccessoryThumbnail + ")",
                                                    backgroundPosition: "center",
                                                    backgroundRepeat: "no-repeat"
                                                }}
                                            ></Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            )}
                        </>
                    )}

                    {menuState === "select-body" && (
                        <div style={{ height: "100%", width: "100%" }}>
                            <button className="btn btn-secondary" onClick={() => setMenuState("main")}>
                                Back
                            </button>
                            <BodyMenu
                                bodyTextures={bodyTextures}
                                bodyTexture={bodyTexture}
                                setBodyTexture={setBodyTexture}
                            />
                        </div>
                    )}
                    {menuState === "select-hair" && (
                        <div>
                            <button className="btn btn-secondary" onClick={() => setMenuState("main")}>
                                Back
                            </button>
                            <HairMenu hairs={hairs} hair={hair} setHair={setHair} />
                        </div>
                    )}

                    {menuState === "select-accessory-head" && (
                        <div>
                            <button className="btn btn-secondary" onClick={() => setMenuState("main")}>
                                Back
                            </button>
                            <p className="mt-3">Select Head Accessory</p>
                            <AvatarPicker
                                results={headAccessories}
                                selected={headAccessory}
                                setSelected={setHeadAccessory}
                            />
                        </div>
                    )}

                    {menuState === "select-accessory-body" && (
                        <div>
                            <button className="btn btn-secondary" onClick={() => setMenuState("main")}>
                                Back
                            </button>
                            <p className="mt-3">Select Body Accessory</p>
                            <AvatarPicker
                                results={bodyAccessories}
                                selected={bodyAccessory}
                                setSelected={setBodyAccessory}
                            />
                        </div>
                    )}

                    {menuState === "avatar-preview-mobile" && (
                        <div>
                            {avatar && hair && bodyTexture && headAccessory && bodyAccessory && (
                                <AvatarPreview
                                    displayName={displayName}
                                    avatar={avatar}
                                    hair={hair}
                                    bodyTexture={bodyTexture}
                                    headAccessory={headAccessory}
                                    bodyAccessory={bodyAccessory}
                                />
                            )}
                        </div>
                    )}
                </Col>
                <Col xs={12} md={6} className="d-none d-md-block">
                    {avatar && hair && bodyTexture && headAccessory && bodyAccessory && (
                        <AvatarPreview
                            displayName={displayName}
                            avatar={avatar}
                            hair={hair}
                            bodyTexture={bodyTexture}
                            headAccessory={headAccessory}
                            bodyAccessory={bodyAccessory}
                        />
                    )}
                </Col>
                <Col className="text-center mt-2" xs={12}>
                    <Button
                        variant="purple-dark"
                        className="me-2 w-100 d-block d-sm-none"
                        onClick={() =>
                            menuState === "avatar-preview-mobile"
                                ? setMenuState("main")
                                : setMenuState("avatar-preview-mobile")
                        }
                    >
                        {menuState === "avatar-preview-mobile" ? "Back" : "Preview"}
                    </Button>
                    {props.fromUserList && props.handleBack && (
                        <Button variant="secondary" className="me-2 w-100" onClick={() => props.handleBack()}>
                            Back
                        </Button>
                    )}
                    {props.fromSettingsMenu && props.handleClose && (
                        <Button variant="secondary" className="me-2 w-100" onClick={() => props.handleClose()}>
                            Back
                        </Button>
                    )}
                    {displayName.length >= 3 ? (
                        <Button variant="success" className="w-100" onClick={() => handleSaveAndContinue()}>
                            {props.fromUserList || props.fromSettings ? "Save" : "Save and Continue"}
                        </Button>
                    ) : (
                        <Button variant="success" className="w-100" disabled>
                            Please enter a name above
                        </Button>
                    )}
                </Col>
            </Row>
        </div>
    );
}
