import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const useAccessoryApi = (scope = null, forceRedirect, location="") => {
    const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
    const [accessories, setAccessories] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [refreshIndex, setRefreshIndex] = useState(0);

    const refresh = () => {
        setRefreshIndex(refreshIndex + 1);
    };

    useEffect(() => {
        (async () => {
            try {
                if (scope === null) {
                    // Anonymous session for public api
                    const response = await fetch(process.env.REACT_APP_MM_API_URL + "avatars/accessory?location=" + 
                    location, );
                    const json = await response.json();
                    setAccessories(json);
                    setLoading(false);
                } else {
                    const token = await getAccessTokenSilently({
                        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
                        scope: scope
                    });
                    console.log("Got token", token);
                    const response = await fetch(process.env.REACT_APP_MM_API_URL + "avatars/accessory?location=" + 
                        location, 
                        {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });
                    const json = await response.json();
                    console.log("Got response from API", json);
                    setAccessories(json);
                    setLoading(false);
                }
            } catch (e) {
                if (forceRedirect) {
                    loginWithRedirect();
                }
                console.error(e);
                setError(e);
            }
        })();
    }, [getAccessTokenSilently, refreshIndex, scope, location]);

    return [accessories, loading, error, refresh];
};

export default useAccessoryApi;
