import React, { useState, useEffect } from "react";
import { Canvas } from "@react-three/fiber";

import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import useDashApi from "../hooks/useDashApi";

export function BodyMenu(props) {
    const title = props.bodyTexture?.title;
    const [selectedClothing, setSelectedClothing] = useState(title.slice(-3));
    const SKIN_COLORS = {
        skin01 : "#fcd8c5", 
        skin02 : "#d4a373",
        skin03 : "#955e06", 
        skin04 : "#6c4201", 
        skin05 : "#3d2b1f", 
         
    };
    const [clothing, clothingLoading, clothingError] = useDashApi("avatars/clothing");
    const [skinColor, setSkinColor] = useState(title.slice(0,6));

    useEffect(() => {        
        const newTitle = skinColor + selectedClothing;
        const newBodyTexture = props.bodyTextures.find(a => a.title === newTitle);
        props.setBodyTexture(newBodyTexture);
        window.APP.store.update({
            profile: {bodyTextureId: newBodyTexture.id}
        });
    }, [skinColor, selectedClothing]);

    return (
        <div className="mt-3" style={{height: "100%"}}>
            <p>Select Skin Color</p>
            <Row className="flex gap-2 justify-content-center">
            {Object.entries(SKIN_COLORS).map(([name, color]) => (
                <Col
                    xs={2}
                    sm={2}
                    md={2}
                    lg={2}
                    xxl={2}
                    className="p-0 m-0 d-flex align-items-center justify-content-center"
                >
                    <Button
                        key={name}
                        className={
                            name === skinColor
                                ? "border-success border p-0"
                                : "border rounded p-0"
                        }
                        style={{
                            backgroundColor: color,
                            borderColor: "#000",
                            width: "60px",
                            height: "60px", 
                        }}
                        onClick={() => setSkinColor(name)}
                    >
                    </Button>
                </Col>
            ))}
            </Row>

            <p className="mt-3">Select Clothing</p>
            {(!clothingLoading && !clothingError && clothing) ? (
                <Row 
                    className="pe-3 ps-3" 
                    style={{
                        display: "flex",
                        flexWrap: "wrap", 
                        maxHeight: "350px", 
                        overflowY: "auto",
                }}>
                {
                    clothing && clothing.map(cloth => {
                    if (cloth.thumbnail.startsWith("/")){
                        cloth.thumbnail = "http://localhost:8000" + cloth.thumbnail;
                    }
                    return (
                        <Col
                            key={cloth.title}
                            xs={4}
                            sm={3}
                            md={3}
                            lg={3}
                            xxl={3}
                            className={
                                cloth.title === selectedClothing
                                    ? "border-success border bg-success p-0"
                                    : "bg-white border rounded p-0"
                            }
                            onClick={() => setSelectedClothing(cloth.title)}
                        >
                            <img draggable="false" className="img-fluid" src={cloth.thumbnail} />
                        </Col>
                    );
                })}
                </Row>
            ) : (
                <p>Loading Clothing</p>
            )}     
            
        </div>
    )
    
}