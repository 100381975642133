import { defineComponent, setDefaultSize, setRemovedRecycleThreshold, Types } from "bitecs";

// TODO this has to happen before all components are defined. Is there a better spot to be doing this?
setDefaultSize(10000);
setRemovedRecycleThreshold(0.2);

export const $isStringType = Symbol("isStringType");

export const MediaVideoData = new Map();

export const MorphAudioFeedback = defineComponent({
    name: 0,
    minValue: 0,
    maxValue: 0
});

export const Networked = defineComponent({
    id: Types.ui32,
    creator: Types.ui32,
    owner: Types.ui32,
    lastOwnerTime: Types.ui32,
    timestamp: Types.ui32
});
Networked.id[$isStringType] = true;
Networked.creator[$isStringType] = true;
Networked.owner[$isStringType] = true;

export const Owned = defineComponent();
export const NetworkedMediaFrame = defineComponent({
    capturedNid: Types.ui32,
    scale: [Types.f32, 3]
});
NetworkedMediaFrame.capturedNid[$isStringType] = true;

export const MediaFrame = defineComponent({
    capturedNid: Types.ui32,
    scale: [Types.f32, 3],
    mediaType: Types.ui8,
    bounds: [Types.f32, 3],
    preview: Types.eid,
    previewingNid: Types.eid
});

export const Text = defineComponent({
    value: Types.ui32,
    maxWidth: Types.ui32,
    color: Types.ui32,
    textAlign: Types.ui32,
    needsUpdate: Types.ui8,
    ref: Types.eid
});
Text.value[$isStringType] = true;
Text.color[$isStringType] = true;
Text.textAlign[$isStringType] = true;

export const FileReplaced = defineComponent({
    newSrc: Types.ui32
});
FileReplaced.newSrc[$isStringType] = true;

export const Interactable = defineComponent({
    type: Types.ui32
});
Interactable.type[$isStringType] = true;

export const ReflectionProbe = defineComponent();
export const Slice9 = defineComponent({
    insets: [Types.ui32, 4],
    size: [Types.f32, 2]
});

export const NetworkedTransform = defineComponent({
    position: [Types.f32, 3],
    rotation: [Types.f32, 4],
    scale: [Types.f32, 3]
});

window.NetworkedTransform = NetworkedTransform;

export const AEntity = defineComponent();
export const Object3DTag = defineComponent();
export const GLTFModel = defineComponent();
export const DirectionalLight = defineComponent();
export const CursorRaycastable = defineComponent();
export const RemoteHoverTarget = defineComponent();
export const NotRemoteHoverTarget = defineComponent();
export const Holdable = defineComponent();
export const RemoveNetworkedEntityButton = defineComponent();
export const Interacted = defineComponent();

export const HandRight = defineComponent();
export const HandLeft = defineComponent();
export const RemoteRight = defineComponent();
export const RemoteLeft = defineComponent();
export const HoveredHandRight = defineComponent();
export const HoveredHandLeft = defineComponent();
export const HoveredRemoteRight = defineComponent();
export const HoveredRemoteLeft = defineComponent();
export const HeldHandRight = defineComponent();
export const HeldHandLeft = defineComponent();
export const HeldRemoteRight = defineComponent();
export const HeldRemoteLeft = defineComponent();
export const Held = defineComponent();
export const Constraint = defineComponent();
export const ConstraintHandRight = defineComponent();
export const ConstraintHandLeft = defineComponent();
export const ConstraintRemoteRight = defineComponent();
export const ConstraintRemoteLeft = defineComponent();
export const OffersRemoteConstraint = defineComponent();
export const HandCollisionTarget = defineComponent();
export const OffersHandConstraint = defineComponent();
export const TogglesHoveredActionSet = defineComponent();

export const HoverButton = defineComponent({ type: Types.ui8 });
export const TextButton = defineComponent({ labelRef: Types.eid });
export const HoldableButton = defineComponent();
export const SingleActionButton = defineComponent();

export const Pen = defineComponent();
export const HoverMenuChild = defineComponent();
export const Static = defineComponent();
export const Inspectable = defineComponent();
export const PreventAudioBoost = defineComponent();
export const Gizmo = defineComponent();
export const IgnoreSpaceBubble = defineComponent();
export const Rigidbody = defineComponent({
    bodyId: Types.ui16,
    collisionGroup: Types.ui32,
    collisionMask: Types.ui32,
    flags: Types.ui8,
    gravity: Types.f32
});
export const PhysicsShape = defineComponent({ bodyId: Types.ui16, shapeId: Types.ui16, halfExtents: [Types.f32, 3] });
export const Pinnable = defineComponent();
export const Pinned = defineComponent();
export const DestroyAtExtremeDistance = defineComponent();

export const MediaLoading = defineComponent();

export const FloatyObject = defineComponent({ flags: Types.ui8, releaseGravity: Types.f32 });
export const MakeKinematicOnRelease = defineComponent();

export const CameraTool = defineComponent({
    snapTime: Types.f32,
    state: Types.ui8,
    captureDurIdx: Types.ui8,
    trackTarget: Types.eid,

    snapMenuRef: Types.eid,
    nextButtonRef: Types.eid,
    prevButtonRef: Types.eid,
    snapRef: Types.eid,
    cancelRef: Types.eid,
    recVideoRef: Types.eid,
    screenRef: Types.eid,
    selfieScreenRef: Types.eid,
    cameraRef: Types.eid,
    countdownLblRef: Types.eid,
    captureDurLblRef: Types.eid,
    sndToggleRef: Types.eid
});
export const MyCameraTool = defineComponent();
export const MediaLoader = defineComponent({
    src: Types.ui32,
    sketchfab_meta: Types.ui32,
    flags: Types.ui8
});
MediaLoader.src[$isStringType] = true;

export const SceneRoot = defineComponent();
export const NavMesh = defineComponent();
export const SceneLoader = defineComponent({ src: Types.ui32 });
SceneLoader.src[$isStringType] = true;

export const TextureCacheKey = defineComponent({
    src: Types.ui32,
    version: Types.ui8
});
TextureCacheKey.src[$isStringType] = true;

export const MediaImage = defineComponent({
    cacheKey: Types.ui32,
    originalSource: Types.ui32
});
MediaImage.cacheKey[$isStringType] = true;
MediaImage.originalSource[$isStringType] = true;

export const MediaVideo = defineComponent({
    autoPlay: Types.ui8,
    originalSource: Types.ui32
});
MediaVideo.originalSource[$isStringType] = true;

export const MediaPDF = defineComponent({
    index: Types.ui8,
    pageCount: Types.ui8,
    pageRef: Types.ui8
});

export const PDFSettingsChanged = defineComponent({
    newIndex: Types.ui8
});

// MediaPDF.pageRef[$isStringType] = true;

export const NetworkedPDF = defineComponent({
    index: Types.ui8
});

export const AIAgent = defineComponent();

export const MediaAudio = defineComponent({
    ref: Types.ui32,
    autoPlay: Types.ui8
});
MediaAudio.ref[$isStringType] = true;

export const AnimationMixer = defineComponent();
export const NetworkedVideo = defineComponent({
    time: Types.f32,
    flags: Types.ui8
});

export const NetworkedAudio = defineComponent({
    time: Types.f32,
    flags: Types.ui8
});

export const VideoMenuItem = defineComponent();
export const VideoMenu = defineComponent({
    videoRef: Types.eid,
    timeLabelRef: Types.eid,
    trackRef: Types.eid,
    headRef: Types.eid,
    playIndicatorRef: Types.eid,
    pauseIndicatorRef: Types.eid
});

export const AudioEmitter = defineComponent();
export const AudioSettingsChanged = defineComponent();
export const Deletable = defineComponent();

export const EnvironmentSettings = defineComponent();
EnvironmentSettings.map = new Map();

// TODO: Store this data elsewhere, since only one or two will ever exist.
export const ObjectMenu = defineComponent({
    pinButtonRef: Types.eid,
    unpinButtonRef: Types.eid,
    cameraFocusButtonRef: Types.eid,
    cameraTrackButtonRef: Types.eid,
    removeButtonRef: Types.eid,
    dropButtonRef: Types.eid,
    inspectButtonRef: Types.eid,
    deserializeDrawingButtonRef: Types.eid,
    openLinkButtonRef: Types.eid,
    refreshButtonRef: Types.eid,
    cloneButtonRef: Types.eid,
    rotateButtonRef: Types.eid,
    mirrorButtonRef: Types.eid,
    scaleButtonRef: Types.eid,
    targetRef: Types.eid
});

export const ObjectMenuTarget = defineComponent();
export const NetworkDebug = defineComponent();
export const AIDebug = defineComponent();
export const NetworkDebugRef = defineComponent({
    ref: Types.eid
});

export const Waypoint = defineComponent({
    position: Types.ui8,
    flags: Types.ui8
});
Waypoint.position[$isStringType] = true;

export const NetworkedWaypoint = defineComponent({
    occupied: Types.ui8
});

// MEGAMINDS Compoonents
export const StudentsCanMove = defineComponent({
    toggled: Types.ui8
});

export const StickyNote = defineComponent({
    toggled: Types.ui8
});

export const Locked = defineComponent({
    toggled: Types.ui8
});

export const Billboard = defineComponent({
    toggled: Types.ui8
});
export const MaterialTag = defineComponent();

export const Equirectangular = defineComponent({
    toggled: Types.ui8
});

export const Question = defineComponent({
    obj2d: Types.ui32,
    bundle: Types.ui32
});
Question.obj2d[$isStringType] = true;
Question.bundle[$isStringType] = true;

export const YouTube = defineComponent({
    obj3d: Types.ui32,
    src: Types.ui32,
    placeholder: Types.ui32
});
YouTube.obj3d[$isStringType] = true;
YouTube.src[$isStringType] = true;
YouTube.placeholder[$isStringType] = true;

export const Gimkit = defineComponent({
    obj3d: Types.ui32,
    src: Types.ui32,
    placeholder: Types.ui32
});
Gimkit.obj3d[$isStringType] = true;
Gimkit.src[$isStringType] = true;
Gimkit.placeholder[$isStringType] = true;

export const Polypad = defineComponent({
    obj3d: Types.ui32,
    src: Types.ui32,
    placeholder: Types.ui32
});
Polypad.obj3d[$isStringType] = true;
Polypad.src[$isStringType] = true;
Polypad.placeholder[$isStringType] = true;

export const Quizizz = defineComponent({
    obj3d: Types.ui32,
    src: Types.ui32,
    placeholder: Types.ui32
});
Quizizz.obj3d[$isStringType] = true;
Quizizz.src[$isStringType] = true;
Quizizz.placeholder[$isStringType] = true;

export const Nearpod = defineComponent({
    obj3d: Types.ui32,
    src: Types.ui32,
    placeholder: Types.ui32
});
Nearpod.obj3d[$isStringType] = true;
Nearpod.src[$isStringType] = true;
Nearpod.placeholder[$isStringType] = true;

export const Kahoot = defineComponent({
    obj3d: Types.ui32,
    src: Types.ui32,
    placeholder: Types.ui32
});
Kahoot.obj3d[$isStringType] = true;
Kahoot.src[$isStringType] = true;
Kahoot.placeholder[$isStringType] = true;

export const GeoGebra = defineComponent({
    obj3d: Types.ui32,
    src: Types.ui32,
    placeholder: Types.ui32
});

GeoGebra.obj3d[$isStringType] = true;
GeoGebra.src[$isStringType] = true;
GeoGebra.placeholder[$isStringType] = true;

export const Peardeck = defineComponent({
    obj3d: Types.ui32,
    src: Types.ui32,
    placeholder: Types.ui32
});
Peardeck.obj3d[$isStringType] = true;
Peardeck.src[$isStringType] = true;
Peardeck.placeholder[$isStringType] = true;

export const Link = defineComponent({
    url: Types.ui32
});
Link.url[$isStringType] = true;

export const Description = defineComponent({
    value: Types.ui32
});
Description.value[$isStringType] = true;

export const Nickname = defineComponent({
    value: Types.ui32
});
Nickname.value[$isStringType] = true;

export const Reaction = defineComponent();
export const ParticleEmitter = defineComponent({
    src: Types.ui32
});
ParticleEmitter.src[$isStringType] = true;

export const SpinningAnimation = defineComponent({
    toggled: Types.ui8,
    animeRef: Types.ui32,
    speed: Types.ui8
});
SpinningAnimation.animeRef[$isStringType] = true;

export const SpinningAnimationUpdated = defineComponent();
export const TextUpdated = defineComponent();

export const Owner = defineComponent({
    value: Types.ui32
});
Owner.value[$isStringType] = true;

export const IsLocalOnly = defineComponent();

export const LiveFeed = defineComponent();
export const ArrowIndicator = defineComponent();
