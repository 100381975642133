import { injectCustomShaderChunks } from "../utils/media-utils";
import { registerComponentInstance, deregisterComponentInstance } from "../utils/component-utils";
import { MediaDevicesEvents } from "../utils/media-devices-utils";
import { createHeadlessModelForSkinnedMesh } from "../utils/three-utils";
import { Layers } from "./layers";

function ensureAvatarNodes(json) {
    const { nodes } = json;
    if (!nodes.some(node => node.name === "Head")) {
        // If the avatar model doesn't have a Head node. The user has probably chosen a custom GLB.
        // So, we need to construct a suitable hierarchy for avatar functionality to work.
        // We re-parent the original root node to the Head node and set the scene root to a new AvatarRoot.

        // Note: We assume that the first node in the primary scene is the one we care about.
        const originalRoot = json.scenes[json.scene].nodes[0];
        nodes.push({ name: "LeftEye", extensions: { MOZ_hubs_components: {} } });
        nodes.push({ name: "RightEye", extensions: { MOZ_hubs_components: {} } });
        nodes.push({
            name: "Head",
            children: [originalRoot, nodes.length - 1, nodes.length - 2],
            extensions: { MOZ_hubs_components: { "scale-audio-feedback": "" } }
        });
        nodes.push({ name: "Neck", children: [nodes.length - 1] });
        nodes.push({ name: "Spine", children: [nodes.length - 1] });
        nodes.push({ name: "Hips", children: [nodes.length - 1] });
        nodes.push({ name: "AvatarRoot", children: [nodes.length - 1] });
        json.scenes[json.scene].nodes[0] = nodes.length - 1;
    }
    return json;
}

AFRAME.registerComponent("player-info", {
    schema: {
        hairSrc: { type: "string" },
        avatarSrc: { type: "string" },
        bodyTextureSrc: { type: "string" },
        headAccessorySrc: { type: "string" },
        bodyAccessorySrc: { type: "string" },
        muted: { default: false },
        avatarType: { type: "string", default: "skinnable" }
    },
    init() {
        this.applyProperties = this.applyProperties.bind(this);
        this.handleRemoteModelError = this.handleRemoteModelError.bind(this);
        this.update = this.update.bind(this);
        this.onPresenceUpdated = this.onPresenceUpdated.bind(this);
        this.onMicStateChanged = this.onMicStateChanged.bind(this);
        this.onAvatarModelLoaded = this.onAvatarModelLoaded.bind(this);

        this.isLocalPlayerInfo = this.el.id === "avatar-rig";
        this.playerSessionId = null;

        if (!this.isLocalPlayerInfo) {
            NAF.utils.getNetworkedEntity(this.el).then(networkedEntity => {
                this.playerSessionId = NAF.utils.getCreator(networkedEntity);
                const playerPresence = window.APP.hubChannel.presence.state[this.playerSessionId];
                if (playerPresence) {
                    this.updateFromPresenceMeta(playerPresence.metas[0]);
                }
            });
        }

        registerComponentInstance(this, "player-info");
    },

    remove() {
        const avatarEl = this.el.querySelector("[avatar-audio-source]");
        APP.isAudioPaused.delete(avatarEl);
        deregisterComponentInstance(this, "player-info");
    },

    onAvatarModelLoaded(e) {
        this.applyProperties(e);
    },

    play() {
        this.el.addEventListener("model-loaded", this.onAvatarModelLoaded);
        this.el.sceneEl.addEventListener("presence_updated", this.onPresenceUpdated);
        window.APP.store.addEventListener("statechanged", this.update);

        this.el.sceneEl.addEventListener("stateadded", this.update);
        this.el.sceneEl.addEventListener("stateremoved", this.update);

        if (this.isLocalPlayerInfo) {
            APP.dialog.on("mic-state-changed", this.onMicStateChanged);
        }
    },

    pause() {
        this.el.removeEventListener("model-loaded", this.onAvatarModelLoaded);
        this.el.sceneEl.removeEventListener("presence_updated", this.onPresenceUpdated);
        this.el.sceneEl.removeEventListener("stateadded", this.update);
        this.el.sceneEl.removeEventListener("stateremoved", this.update);
        window.APP.store.removeEventListener("statechanged", this.update);

        if (this.isLocalPlayerInfo) {
            APP.dialog.off("mic-state-changed", this.onMicStateChanged);
        }
    },

    onPresenceUpdated(e) {
        this.updateFromPresenceMeta(e.detail);
    },

    updateFromPresenceMeta(presenceMeta) {
        if (!this.playerSessionId && this.isLocalPlayerInfo) {
            this.playerSessionId = NAF.clientId;
        }
        if (!this.playerSessionId) return;
        if (this.playerSessionId !== presenceMeta.sessionId) return;

        this.permissions = presenceMeta.permissions;
    },

    update(oldData) {
        if (this.data.muted !== oldData.muted) {
            this.el.emit("remote_mute_updated", { muted: this.data.muted });
        }
        this.applyProperties();
    },

    can(perm) {
        return !!this.permissions && this.permissions[perm];
    },

    applyProperties(e) {
        const modelEl = this.el.querySelector(".model");
        const hairModelEl = this.el.querySelector(".hairModel");
        const headAccessoryEl = this.el.querySelector(".headAccessoryModel");
        const bodyAccessoryEl = this.el.querySelector(".bodyAccessoryModel");

        if (this.data.avatarSrc && modelEl && this.data.bodyTextureSrc) {
            modelEl.setAttribute("gltf-model-plus", "src", this.data.avatarSrc);
            const textureEl = this.data.bodyTextureSrc;
            const textureLoader = new THREE.TextureLoader();
            textureLoader.load(
                textureEl,
                function (texture) {
                    const model = modelEl.object3D;

                    model.traverse(child => {
                        if (child.name === "head_mesh" && child.isMesh) {
                            if (child.material.map) {
                                child.material.map.dispose(); // Dispose of old texture
                                child.material.map = null; // Remove reference to old texture
                            }

                            child.material.map = texture;
                            child.material.map.flipY = false;
                            child.material.map.offset.set(0, 0);
                            child.material.map.repeat.set(1, 1);
                            child.material.needsUpdate = true;
                        }
                    });
                },
                undefined,
                function (error) {
                    console.error("Error loading texture:", error);
                }
            );
        }

        if (this.data.hairSrc && hairModelEl) {
            hairModelEl.setAttribute("gltf-model-plus", "src", this.data.hairSrc);
        }

        if (this.data.headAccessorySrc && headAccessoryEl) {
            headAccessoryEl.setAttribute("gltf-model-plus", "src", this.data.headAccessorySrc);
        }

        if (this.data.bodyAccessorySrc && bodyAccessoryEl) {
            bodyAccessoryEl.setAttribute("gltf-model-plus", "src", this.data.bodyAccessorySrc);
        }

        if (!this.isLocalPlayerInfo && this.data.bodyTextureSrc) {
            
        }

        if (!e || e.target === modelEl) {
            const uniforms = injectCustomShaderChunks(this.el.object3D);
        }

        const sessionId = this.isLocalPlayerInfo ? NAF.clientId : this.playerSessionId;

        const avatarEl = this.el.querySelector("[avatar-audio-source]");
        if (this.data.muted) {
            APP.isAudioPaused.add(avatarEl);
        } else {
            APP.isAudioPaused.delete(avatarEl);
        }
    },

    handleRemoteModelError() {
        this.applyProperties();
    },

    onMicStateChanged({ enabled }) {
        this.el.setAttribute("player-info", { muted: !enabled });
    }
});
