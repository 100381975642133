import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { Canvas, useThree } from "@react-three/fiber";

import { Suspense } from "react";
import { useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { TextureLoader } from "three";
import { DoubleSide } from "three";

import { Text as TroikaText } from "troika-three-text";

const CameraController = () => {
    const { camera, gl } = useThree();
    useEffect(() => {
        const controls = new OrbitControls(camera, gl.domElement);
        controls.minDistance = 0.3;
        controls.maxDistance = 2;

        controls.mouseButtons = {
            LEFT: THREE.MOUSE.ROTATE,
            MIDDLE: THREE.MOUSE.DOLLY,
        };
        return () => {
            controls.dispose();
        };
    }, [camera, gl]);
    return null;
};

function Fallback() {
    const text = new TroikaText();

    // Set properties to configure:
    text.text = "Loading...";
    text.anchorX = "center";
    text.anchorY = "middle";

    text.fontSize = 0.075;
    text.side = "double";
    text.position.z = -2;
    text.color = 0x000000;
    text.outlineColor = 0x000000;
    text.outlineOpacity = 1;

    // Update the rendering:
    text.sync();

    return <primitive object={text} position={[0, 0, 0.05]} />;
}

function Nameplate({ displayName }) {
    const name = new TroikaText();

    // Set properties to configure:
    name.text = displayName ? displayName : "Example Name";
    name.anchorX = "center";
    name.anchorY = "middle";

    name.fontSize = 0.075;
    name.side = "double";
    name.position.z = -2;
    name.color = 0xffffff;
    name.outlineColor = 0x000000;
    name.outlineOpacity = 1;

    // Update the rendering:
    name.sync();

    return (
        <mesh position={[0, 0.25, 0]}>
            <primitive object={name} position={[0, 0, 0.05]} />

            <meshBasicMaterial
                args={[
                    {
                        color: "#000",
                        side: DoubleSide,
                        transparent: true,
                        opacity: 0.5,
                    },
                ]}
            />
        </mesh>
    );
}

function Avatar({ avatarUrl, textureUrl }) {
    const [newResult, setResult] = useState(null);

    const result = useLoader(GLTFLoader, avatarUrl);
    const texture = useLoader(TextureLoader, textureUrl);

    texture.wrapS = THREE.RepeatWrapping;
    texture.wrapT = THREE.RepeatWrapping;

    useEffect(() => {
        const result = useLoader(GLTFLoader, avatarUrl);
        setResult(result);

        const mesh = result.scene.getObjectByProperty("type", "SkinnedMesh");

        mesh.traverse((child) => {
            if (child.isMesh && child.material) {
                if (child.material.map) {
                    child.material.map.dispose(); // Dispose of old texture
                    child.material.map = null;  // Remove reference to old texture
                }

                child.material.map = texture;
                child.material.map.flipY = false;
                child.material.map.offset.set(0, 0);
                child.material.map.repeat.set(1, 1);
                child.material.needsUpdate = true;
            }
        });

        // Hide hands
        const rightHand = result.scene.getObjectByName("RightHand", true);
        if (rightHand) rightHand.visible = false;

        const leftHand = result.scene.getObjectByName("LeftHand", true);
        if (leftHand) leftHand.visible = false;
    }, [avatarUrl, texture]);

    if (newResult === null) {
        return <primitive object={result.scene} position={[0, -0.6, 0]} />;
    }

    return <primitive object={newResult.scene} position={[0, -0.6, 0.02]} />;
}


function Hair({ hairUrl }) {
    const [newResult, setResult] = useState(null);

    const result = useLoader(GLTFLoader, hairUrl);

    useEffect(() => {
        const result = useLoader(GLTFLoader, hairUrl);
        setResult(result);
    }, [hairUrl]);

    if (newResult === null) {
        return <primitive object={result.scene} position={[0, -0.6, 0]} />;
    }

    return <primitive object={newResult.scene} position={[0, -0.6, 0.02]} />;
}

function HeadAccessory({ accesoryUrl }) {
    const [newResult, setResult] = useState(null);

    const result = useLoader(GLTFLoader, accesoryUrl);

    useEffect(() => {
        const result = useLoader(GLTFLoader, accesoryUrl);
        setResult(result);
    }, [accesoryUrl]);

    if (newResult === null) {
        return <primitive object={result.scene} position={[0, -0.6, 0]} />;
    }

    return <primitive object={newResult.scene} position={[0, -0.6, 0.02]} />;
}

function BodyAccessory({ accesoryUrl }) {
    const [newResult, setResult] = useState(null);

    const result = useLoader(GLTFLoader, accesoryUrl);

    useEffect(() => {
        const result = useLoader(GLTFLoader, accesoryUrl);
        setResult(result);
    }, [accesoryUrl]);

    if (newResult === null) {
        return <primitive object={result.scene} position={[0, -0.6, 0]} />;
    }

    return <primitive object={newResult.scene} position={[0, -0.6, 0.02]} />;
}


Avatar.propTypes = {
    avatarUrl: PropTypes.string,
};

Hair.propTypes = {
    hairUrl: PropTypes.string,
};

HeadAccessory.propTypes = {
    accesoryUrl: PropTypes.string,
};

BodyAccessory.propTypes = {
    accesoryUrl: PropTypes.string,
};

/*
 * I took this out for now as it creates too much visual impact to have a grassy
 * hill in the background. In the future we can use this to add some chill wardrobe or something for the bots.
function Scenery({ children }) {
    return (
        <primitive
            object={result.scene}
            scale={[0.2, 0.2, 0.2]}
            position={[0, -0.8, 0]}
        >
            {children}
        </primitive>
    );
}
*/

export function AvatarPreview(props) {
    const avatarUrl = props.avatar.glb.startsWith("/")
        ? "http://localhost:8000" + props.avatar.glb
        : props.avatar.glb;

    const hairUrl = props.hair?.glb 
        ? props.hair.glb.startsWith("/")
            ? "http://localhost:8000" + props.hair.glb
            : props.hair.glb
        : null;

    const textureUrl = props.bodyTexture.texture_file.startsWith("/")
        ? "http://localhost:8000" + props.bodyTexture.texture_file
        : props.bodyTexture.texture_file;    

    const headAccesoryUrl = props.headAccessory?.glb
        ? props.headAccessory.glb.startsWith("/")
            ? "http://localhost:8000" + props.headAccessory.glb
            : props.headAccessory.glb
        : null;

    const bodyAccesoryUrl = props.bodyAccessory?.glb
        ? props.bodyAccessory.glb.startsWith("/")
            ? "http://localhost:8000" + props.bodyAccessory.glb
            : props.bodyAccessory.glb
        : null;

    return (
        <Suspense fallback={<p>Loading preview</p>}>
            <Canvas
                className="canvas-cursor"
                style={{width: "100%",
                        height: "530px",
                        backgroundColor: "#fff",}}
                renderer={{ alpha: true }}
                camera={{ position: [0.3, 0.1, 0.7] }}
            >
                <Suspense fallback={<Fallback />}>
                    {hairUrl && (
                        <Hair hairUrl={hairUrl}/>
                    )}          
                    <Avatar avatarUrl={avatarUrl}
                            textureUrl={textureUrl}
                    />
                    {headAccesoryUrl && (
                        <HeadAccessory accesoryUrl={headAccesoryUrl}/>
                    )}
                    {bodyAccesoryUrl && (
                        <BodyAccessory accesoryUrl={bodyAccesoryUrl}/>
                    )}
                </Suspense>
                <Nameplate displayName={props.displayName} />
                <CameraController />
                <ambientLight intensity="0.3" />
                <directionalLight
                    position={[-10, 30, 0]}
                    rotation={[58.767, 20.0, -25.311]}
                    color="#ffffff"
                    intensity="0.5"
                    castShadow={true}
                />
                <spotLight position={[10, 10, 10]} angle={0.15} intensity="0.011" penumbra={1} />
            </Canvas>
        </Suspense>
    );
}
