import { paths } from "./userinput/paths";
import { SOUND_SNAP_ROTATE } from "./sound-effects-system";
import { waitForDOMContentLoaded } from "../utils/async-utils";
import { childMatch, rotateInPlaceAroundWorldUp, affixToWorldUp } from "../utils/three-utils";

const getCurrentPlayerHeight = (function () {
    let avatarPOV;
    let avatarRig;
    return function getCurrentPlayerHeight(world) {
        avatarPOV = avatarPOV || document.getElementById("avatar-pov-node");
        avatarRig = avatarRig || document.getElementById("avatar-rig");
        avatarRig.object3D.updateMatrices();
        avatarPOV.object3D.updateMatrices();
        if (world) {
            return avatarPOV.object3D.matrixWorld.elements[13] - avatarRig.object3D.matrixWorld.elements[13];
        }
        return avatarPOV.object3D.matrix.elements[13];
    };
})();

//import { m4String } from "../utils/pretty-print";
const NAV_ZONE = "character";
const isMobile = AFRAME.utils.device.isMobile();

const calculateDisplacementToDesiredPOV = (function () {
    const translationCoordinateSpace = new THREE.Matrix4();
    const translated = new THREE.Matrix4();
    const localTranslation = new THREE.Matrix4();
    return function calculateDisplacementToDesiredPOV(
        povMat4,
        allowVerticalMovement,
        localDisplacement,
        displacementToDesiredPOV
    ) {
        localTranslation.makeTranslation(localDisplacement.x, localDisplacement.y, localDisplacement.z);
        translationCoordinateSpace.extractRotation(povMat4);
        if (!allowVerticalMovement) {
            affixToWorldUp(translationCoordinateSpace, translationCoordinateSpace);
        }
        translated.copy(translationCoordinateSpace).multiply(localTranslation);
        return displacementToDesiredPOV.setFromMatrixPosition(translated);
    };
})();

/**
 * A character controller that moves the avatar.
 * The controller accounts for playspace offset and orientation and depends on the nav mesh system for translation.
 * @namespace avatar
 */
const BASE_SPEED = 4.6; //TODO: in what units?
export class CharacterControllerSystem {
    constructor(scene) {
        this.scene = scene;
        this.fly = false;
        this.shouldLandWhenPossible = false;
        this.navGroup = null;
        this.navNode = null;
        this.relativeMotion = new THREE.Vector3(0, 0, 0);
        this.nextRelativeMotion = new THREE.Vector3(0, 0, 0);
        this.dXZ = 0;
        this.scene.addEventListener("nav-mesh-loaded", () => {
            this.navGroup = null;
            this.navNode = null;
        });
        waitForDOMContentLoaded().then(() => {
            this.avatarPOV = document.getElementById("avatar-pov-node");
            this.avatarRig = document.getElementById("avatar-rig");
        });
    }

    enqueueRelativeMotion(motion) {
        this.relativeMotion.add(motion);
    }
    enqueueInPlaceRotationAroundWorldUp(dXZ) {
        this.dXZ += dXZ;
    }

    teleportTo = (function () {
        const rig = new THREE.Vector3();
        const head = new THREE.Vector3();
        const deltaFromHeadToTargetForHead = new THREE.Vector3();
        const targetForHead = new THREE.Vector3();
        const targetForRig = new THREE.Vector3();
        return function teleportTo(targetWorldPosition) {
            this.isMotionDisabled = false;
            this.avatarRig.object3D.getWorldPosition(rig);
            this.avatarPOV.object3D.getWorldPosition(head);
            targetForHead.copy(targetWorldPosition);
            targetForHead.y += this.avatarPOV.object3D.position.y;
            deltaFromHeadToTargetForHead.copy(targetForHead).sub(head);
            targetForRig.copy(rig).add(deltaFromHeadToTargetForHead);
            const navMeshExists = NAV_ZONE in this.scene.systems.nav.pathfinder.zones;
            this.findPositionOnNavMesh(targetForRig, targetForRig, this.avatarRig.object3D.position, navMeshExists);
            this.avatarRig.object3D.matrixNeedsUpdate = true;

            console.log("Teleporting to", targetWorldPosition);
        };
    })();

    tick = (function () {
        const snapRotatedPOV = new THREE.Matrix4();
        const newPOV = new THREE.Matrix4();
        const displacementToDesiredPOV = new THREE.Vector3();

        const startPOVPosition = new THREE.Vector3();
        const desiredPOVPosition = new THREE.Vector3();
        const navMeshSnappedPOVPosition = new THREE.Vector3();
        const startTransform = new THREE.Matrix4();
        const startTranslation = new THREE.Matrix4();
        const v = new THREE.Vector3();

        return function tick(t, dt) {
            const entered = this.scene.is("entered");
            if (!entered) return;
            this.sfx = this.sfx || this.scene.systems["hubs-systems"].soundEffectsSystem;

            const userinput = AFRAME.scenes[0].systems.userinput;
            const wasFlying = this.fly;
            if (userinput.get(paths.actions.toggleFly)) {
                this.shouldLandWhenPossible = false;
                this.avatarRig.messageDispatch.dispatch("/fly"); // TODO: Separate the logic about displaying the message from toggling the fly state in such a way that it is clear that this.fly will be toggled here
            }
            const didStopFlying = wasFlying && !this.fly;
            if (!this.fly && this.shouldLandWhenPossible) {
                this.shouldLandWhenPossible = false;
            }
            if (this.fly) {
                this.navNode = null;
            }
            const preferences = window.APP.store.state.preferences;
            const snapRotateLeft = userinput.get(paths.actions.snapRotateLeft);
            const snapRotateRight = userinput.get(paths.actions.snapRotateRight);

            if (snapRotateLeft) {
                this.dXZ += (preferences.snapRotationDegrees * Math.PI) / 180;
            }
            if (snapRotateRight) {
                this.dXZ -= (preferences.snapRotationDegrees * Math.PI) / 180;
            }
            if (snapRotateLeft || snapRotateRight) {
                this.scene.systems["hubs-systems"].soundEffectsSystem.playSoundOneShot(SOUND_SNAP_ROTATE);
            }

            const characterAcceleration = userinput.get(paths.actions.characterAcceleration);
            if (characterAcceleration) {
                const zCharacterAcceleration = -1 * characterAcceleration[1];
                this.relativeMotion.set(
                    this.relativeMotion.x +
                        (preferences.disableMovement || preferences.disableStrafing ? 0 : characterAcceleration[0]),
                    this.relativeMotion.y,
                    this.relativeMotion.z +
                        (preferences.disableMovement
                            ? 0
                            : preferences.disableBackwardsMovement
                            ? Math.min(0, zCharacterAcceleration)
                            : zCharacterAcceleration)
                );
            }
            const lerpC = 0.45; // TODO: To support drifting ("ice skating"), motion needs to keep initial direction
            this.nextRelativeMotion.copy(this.relativeMotion).multiplyScalar(lerpC);
            this.relativeMotion.multiplyScalar(1 - lerpC);

            this.avatarPOV.object3D.updateMatrices();
            rotateInPlaceAroundWorldUp(this.avatarPOV.object3D.matrixWorld, this.dXZ, snapRotatedPOV);

            newPOV.copy(snapRotatedPOV);

            const navMeshExists = NAV_ZONE in this.scene.systems.nav.pathfinder.zones;
            if (!this.isMotionDisabled) {
                const playerScale = v.setFromMatrixColumn(this.avatarPOV.object3D.matrixWorld, 1).length();
                const triedToMove = this.relativeMotion.lengthSq() > 0.000001;

                if (triedToMove) {
                    const speedModifier = !!AFRAME.utils.device.isMobile() ? 0.68 : 1;
                    calculateDisplacementToDesiredPOV(
                        snapRotatedPOV,
                        this.fly || !navMeshExists,
                        this.relativeMotion.multiplyScalar(
                            ((userinput.get(paths.actions.boost) ? 2 : 1) *
                                speedModifier *
                                BASE_SPEED *
                                Math.sqrt(playerScale) *
                                dt) /
                                1000
                        ),
                        displacementToDesiredPOV
                    );

                    newPOV
                        .makeTranslation(
                            displacementToDesiredPOV.x,
                            displacementToDesiredPOV.y,
                            displacementToDesiredPOV.z
                        )
                        .multiply(snapRotatedPOV);
                }

                const shouldRecomputeNavGroupAndNavNode = didStopFlying || this.shouldLandWhenPossible;
                const shouldResnapToNavMesh = navMeshExists && (shouldRecomputeNavGroupAndNavNode || triedToMove);

                let squareDistNavMeshCorrection = 0;

                if (shouldResnapToNavMesh) {
                    this.findPOVPositionAboveNavMesh(
                        startPOVPosition.setFromMatrixPosition(this.avatarPOV.object3D.matrixWorld),
                        desiredPOVPosition.setFromMatrixPosition(newPOV),
                        navMeshSnappedPOVPosition,
                        shouldRecomputeNavGroupAndNavNode
                    );

                    squareDistNavMeshCorrection = desiredPOVPosition.distanceToSquared(navMeshSnappedPOVPosition);

                    if (this.fly && this.shouldLandWhenPossible && squareDistNavMeshCorrection < 0.5) {
                        this.shouldLandWhenPossible = false;
                        this.fly = false;
                        newPOV.setPosition(navMeshSnappedPOVPosition);
                    } else if (!this.fly) {
                        newPOV.setPosition(navMeshSnappedPOVPosition);
                    }
                }
            }

            childMatch(this.avatarRig.object3D, this.avatarPOV.object3D, newPOV);
            this.relativeMotion.copy(this.nextRelativeMotion);
            this.dXZ = 0;
        };
    })();

    getClosestNode(pos) {
        const pathfinder = this.scene.systems.nav.pathfinder;
        if (!pathfinder.zones[NAV_ZONE].groups[this.navGroup]) {
            return null;
        }
        return (
            pathfinder.getClosestNode(pos, NAV_ZONE, this.navGroup, true) ||
            pathfinder.getClosestNode(pos, NAV_ZONE, this.navGroup)
        );
    }

    findPOVPositionAboveNavMesh = (function () {
        const startingFeetPosition = new THREE.Vector3();
        const desiredFeetPosition = new THREE.Vector3();
        // TODO: Here we assume the player is standing straight up, but in VR it is often the case
        // that you want to lean over the edge of a balcony/table that does not have nav mesh below.
        // We should find way to allow leaning over the edge of a balcony and maybe disallow putting
        // your head through a wall.
        return function findPOVPositionAboveNavMesh(
            startPOVPosition,
            desiredPOVPosition,
            outPOVPosition,
            shouldRecomputeGroupAndNode
        ) {
            const playerHeight = getCurrentPlayerHeight(true);
            startingFeetPosition.copy(startPOVPosition);
            startingFeetPosition.y -= playerHeight;
            desiredFeetPosition.copy(desiredPOVPosition);
            desiredFeetPosition.y -= playerHeight;
            this.findPositionOnNavMesh(
                startingFeetPosition,
                desiredFeetPosition,
                outPOVPosition,
                shouldRecomputeGroupAndNode
            );
            outPOVPosition.y += playerHeight;
            return outPOVPosition;
        };
    })();

    findPositionOnNavMesh(start, end, outPos, shouldRecomputeGroupAndNode) {
        const pathfinder = this.scene.systems.nav.pathfinder;
        if (!(NAV_ZONE in pathfinder.zones)) return;
        this.navGroup =
            shouldRecomputeGroupAndNode || this.navGroup === null
                ? pathfinder.getGroup(NAV_ZONE, end, true, true)
                : this.navGroup;
        this.navNode =
            shouldRecomputeGroupAndNode || this.navNode === null || this.navNode === undefined
                ? this.getClosestNode(end)
                : this.navNode;
        if (this.navNode === null || this.navNode === undefined) {
            // this.navNode can be null if it has never been set or if getClosestNode fails,
            // and it can be undefined if clampStep fails, so we have to check both. We do not
            // simply check if it is falsey (!this.navNode), because 0 (zero) is a valid value,
            // and 0 is falsey.
            outPos.copy(end);
        } else {
            this.navNode = pathfinder.clampStep(start, end, this.navNode, NAV_ZONE, this.navGroup, outPos);
        }
        return outPos;
    }

    enableFly(enabled) {
        if (enabled && window.APP.hubChannel && window.APP.hubChannel.can("fly")) {
            this.fly = true;
        } else {
            this.fly = false;
        }
        return this.fly;
    }
}
